@import '../../../styles/appscape/variables';

.navigation-container {
  z-index: 2
}

.category-navigation {
  display: none;
  box-sizing: border-box;

  @media (min-width: $tablet) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 0;
    z-index: 2;
    height: 100%;
    width: 24rem;
    overflow-y: auto;
    padding: 2rem;
    background-color: $white;
    box-shadow: inset 0 7px 9px -7px rgb(0 0 0 / 13%);
  }

  @media (max-width: $tablet-max) {
    &.display {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 0;
      height: 100%;
      width: 100vw;
      overflow-y: auto;
      padding: 2rem 2rem 5rem 2rem;
      background-color: $white;
    }
  }

  .category-content {
    >h2 {
      color: $black;
      font-family: 'DM Sans Medium';
      font-weight: 500;
      font-size: $fontsize-large;
      font-weight: normal;
    }

    .category-group {
      margin-top: 1.625rem;

      >h3 {
        color: $FUI-type-secondary;
        font-family: 'DM Sans Medium';
        font-size: $fontsize-extra-small;
        font-weight: normal;
        margin-bottom: 0.35rem;
      }
    }
  }

  .category-footer {
    width: 100%;
    align-self: flex-end;
    margin-top: 35px;
  }
}