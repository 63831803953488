@import '../../../styles/CBT/variables';

#CBT, .popup-modal {
  .cta-button {
    background-color: $FUI-primary;
    border-radius: 2px;
    padding: 0.625rem 1.25rem;
    margin: 1rem 1rem 0;
    font-family: 'Segoe UI Regular';
    font-weight: 600;
    font-size: $fontsize-small;
    line-height: $lineheight-small;
    color: $white;

    @media (min-width: $tablet) {
      font-size: $fontsize-regular;
      line-height: $lineheight-regular;
    }

    &:hover {
      background-color: $FUI-dark-alt;
    }

    &:disabled {
      color: $FUI-disabled;
      background-color: $FUI-grey20;
    }

    &:active {
      background-color: $FUI-dark;
    }

    &:focus {
      outline: 1px solid $FUI-primary;
      border: 1px solid $white;
    }
  }
}