$fontsize-extra-large: 1.25rem; // 20/16
$fontsize-large: 1.125rem; // 18/16
$fontsize-regular: 1rem; // 16/16
$fontsize-small: 0.875rem; // 14/16
$fontsize-extra-small: 0.75rem; // 12/16

$lineheight-extra-large: 1.75rem; // 28/16
$lineheight-large: 1.5rem; // 24/16
$lineheight-regular: 1.375rem; // 22/16
$lineheight-small: 1.25rem; // 20/16
$lineheight-extra-small: 1rem; // 16/16