@import '../../../styles/InfoCards/variables';

#info {
  .ms-DocumentCard {
    height: 136px;
    margin-bottom: 0.625rem;
    margin-right: 1rem;
    border-radius: 2px;
    box-shadow: $infocard-box-shadow;
    min-width: 350px;
    max-width: unset;
    width: calc(50% - 1.5rem);    

    @media (max-width: $tablet) {
      width: 100%;
      max-width: 404px;
      min-width: unset;      
    }

    > div {
      border-bottom: none;
    }

    .ms-Image {
      height: 136px;
      width: 115px;

      @media (min-width: $tablet) {
        width: 180px;
      }
    }

    .ms-DocumentCardDetails {
      justify-content: flex-start;
      flex-grow: 1;
      margin: 0.75rem;

      h3 {
        font-family: 'Segoe UI SemiBold';
        font-weight: 400;
        font-size: $fontsize-small;
        line-height: $lineheight-small;
        color: $FUI-grey190;
        margin-bottom: 0.75rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      p {
        font-family: 'Segoe UI Regular';
        font-weight: 400;
        font-size: $fontsize-extra-small;
        line-height: $lineheight-extra-small;
        color: $FUI-grey190;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }
    }
  }
}