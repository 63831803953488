@import '../../../styles/CBT/variables';

#CBT {
  .persona-wrapper {
    padding: 0.375rem 0;

    .ms-Persona-details {
      flex-direction: row;
      justify-content: flex-start;
    }

    .ms-Persona-primaryText, .ms-Persona-secondaryText {
      color: $FUI-tertiary;
      font-family: 'Segoe UI Regular';
      font-weight: 600;
      font-size: $fontsize-small;
      line-height: $lineheight-small;
    }

    .ms-Persona-secondaryText {
      margin-left: 0.2rem;
    }

    &.secondary-text {
      .ms-Persona-secondaryText::before {
        content: '|';
        padding-right: 0.2rem;
      }
    }
  }
}