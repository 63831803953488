@import './variables/';

table {
    width: 100%;
    color: $FUI-darker;
    border-color: $FUI-grey30;

    th,
    td {
        text-align: left;
        padding: 1rem 0;
    }

    td {
        border-bottom-color: inherit;
        border-bottom-style: solid;
        border-bottom-width: 1px;
    }
}