@import '../../../styles/CBT/variables';

#CBT {
  .image-message {
    margin: 0 1rem 1rem;
    
    img {
      max-width: 100%;
      height: auto;
      max-height: 600px;
    }
  }
}