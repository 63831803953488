@import '../../../styles/CBT/variables';

#CBT {
  .warning-display {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 5rem;
    width: calc(100% - 3rem);
    max-width: calc(700px - 3rem);
    margin: 0 1rem;
    padding: 0.5rem;
    z-index: 2;
    background-color: $FUI-error-fill;

    @media (min-width: $tablet) {
      bottom: 7rem;
    }

    &.hidden {
      display: none;
      visibility: hidden;
    }

    &.error {
      background-color: $FUI-error-fill;

      .icon {
        color: $FUI-status-error;
      }
    }

    &.info {
      position: static;
      margin-bottom: 1rem;
      background-color: $FUI-light;

      .icon {
        color: $FUI-grey130;
      }
    }
    
    .icon {
      flex-grow: 0;
      flex-shrink: 0;
      margin-left: 0.25rem;
      margin-right: 0.5rem;
    }

    > p {
      font-family: 'Segoe UI Regular';
      color: $FUI-grey190;
      font-size: $fontsize-extra-small;
      line-height: $lineheight-extra-small;
    }
  }
}