@import '../../../styles/CBT/variables';

#CBT {
  .text-input {
    margin: 0 1rem 1rem;
   
    textarea {
      min-height: 9rem;
      font-family: 'Segoe UI Regular';
      font-size: $fontsize-small;
      line-height: $lineheight-small;

      &:read-only{
        background: $FUI-grey20;
        cursor: not-allowed;
      }
    }

    input {
      font-family: 'Segoe UI Regular';
      font-size: $fontsize-small;
      line-height: $lineheight-small;

      &:read-only{
        background: $FUI-grey20;
        cursor: not-allowed;
      }
    }
  }
 
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $FUI-grey110;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: $FUI-grey110;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: $FUI-grey110;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: $FUI-grey110;
  }
}
