@import '../../../styles/QandA/variables';

#QandA {
  .chart-component {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;   
    max-width: 490px;

    text.highcharts-credits {
      display: none;
    }
  }
}