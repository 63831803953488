@import '../../../styles/CBT/variables';

#CBT {
  .slider-wrapper {
    width: auto;
    max-width: 600px;
    margin: 0 -1rem ;

    @media (min-width: $tablet) {
      margin: 0 1rem ;
    }

    .slick-list {
      margin-left: 1rem;

      @media (min-width: $tablet) {
        margin-left: 0rem;
      }
    }

    .slider-tile {
      width: 200% !important;
      visibility: hidden;

      &.visible {
        visibility: visible;
      }

      &.active {
        position: relative;
        z-index: 1;

        .pane-wrapper {
          margin-left: -50%;
          max-width: 600px;
          width: 100vw;
          min-height: 250px;
          height: auto;
          margin-top: 35px;
          margin-bottom: 2rem;
        }

        .pane {
          margin: auto;
          min-height: 198px;
          height: auto;
          width: 198px;
          padding: 1.625rem;
          border: 1px solid $FUI-grey50;
          box-shadow: 3px 4px 20px 6px rgba(0, 0, 0, 0.25);

          .p {
            margin-top: 1.5rem;
            font-size: $fontsize-regular;
            line-height: $lineheight-regular;
          }

          .h3 {
            font-size: $fontsize-large;
            line-height: $lineheight-large;
          }

          .sliderIcon {
            display: flex;
            position: absolute;
            font-size: 1.5rem;
            line-height: 100%;
            color: $FUI-darker;
            top: 75%;
            
            &.ArrowLeft {
              transform: rotate(240deg);
              margin-right: 250px;
            }

            &.ArrowRight {
              transform: rotate(30deg);
              margin-left: 250px;
            }
          }
        }
      }
    }

    .pane-wrapper {
      margin-top: 57.5px;
    }

    .pane {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      border-radius: 10px;
      box-shadow: 3px 4px 20px 6px rgba(0, 0, 0, 0.06);
      padding: 1rem;
      margin: 22.5px 0;
      min-height: 173px;
      height: auto;
      width: 173px;

      .p {
        margin-top: 1rem;
        font-size: $fontsize-small;
        line-height: $lineheight-small;
        overflow: hidden;
      }

      .h3 {
        font-size: $fontsize-regular;
        line-height: $lineheight-regular;
      }
      
      .sliderIcon{
        display: none;
      }
    }

    .arrow {
      position: absolute;
      cursor: pointer;
      z-index: 10;
      min-height: 250px;
      height: 100%;
      width: calc((100vw - 230px) / 2);
      max-width: 170px;
      top: 35px;

      &.next {
        right: 0;
      }

      &.prev {
        left: 0;
      }
    }
  }
}