@import '../../../styles/appscape/variables';

.category-button {
  display: flex;
  align-items: center;
  height: 4rem;
  width: 100%;
  background-color: $white;
  border: 1px solid $grey-porcelain;
  border-radius: 0.625rem;
  margin-bottom: 0.35rem;
  padding: 0 1.5rem;

  &.active {
    background-color: $blue-link-water;
    border-color: $FUI-dark-alt;
  }

  &:hover {
    cursor: pointer;
    background-color: $blue-link-water;
    border: 1px solid $FUI-dark-alt;
  }

  &.main {
    span {
      font-family: 'DM Sans Bold';
      font-weight: 700;
    }
  }

  >span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  span {
    font-family: 'DM Sans Regular';
    font-weight: 400;
    font-size: $fontsize-regular;
    line-height: $lineheight-regular;
    color: $blue-big-stone;
    text-align: left;
  }

  .ms-Persona {
    margin-right: 1rem;
  }

  .icon {
    font-size: 30px;
    color: $blue-indigo;
    margin-right: 1rem;

    &.category {
      color: #1EAB95;
    }
  }
}