@import '../../styles/QandA/variables';

#QandA {
  font-family: 'DM Sans Regular', Arial, Helvetica, sans-serif;

  .QandA-dialog {
    display: flex;
    flex-direction: column;
    max-width: 940px;
    margin: auto;
    padding: 2rem 1rem;
    height: 100%;

    @media (min-width: $tablet) {
      padding: 3rem 2rem;
    }

    .date-time {
      align-self: center;
      color: $FUI-type-secondary;
      font-size: $fontsize-extra-small;
      margin-bottom: 1rem;
    }

    .info-cards {
      display: flex;
      flex-direction: column;

      @media (min-width: $tablet) {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    .QandA-background {
      position: absolute;
      bottom: 67px;
      right: 0;
      width: auto;
      max-width: 80vw;
      bottom: 67px;
      height: auto;
      visibility: visible;
      opacity: 0;
      animation-delay: 1s;
      animation-name: fadeIn;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-timing-function: linear;
      animation-direction: normal;
      z-index: 1;

      @media (min-width: $tablet) {
        max-width: unset;
        bottom: 0;
      }
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  }

  .QandA-bot-info {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    .bot-icon {
      width: 30px;
      height: 30px;
      margin-right: 1rem;
    }

    span {
      font-family: 'DM Sans Medium';
      color: $blue-big-stone;
      font-size: $fontsize-regular;
    }
  }

  .QandA-sender-info {
    display: flex;
    align-items: center;
    align-self: flex-end;
    margin-bottom: 0.5rem;

    .sender-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      color: $white;
      font-size: $fontsize-small;
      background-color: $blue-indigo;
    }
  }

  .page-error {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    padding: 1rem;
    font-family: 'Segoe UI Regular';
    font-size: $fontsize-regular;
    line-height: $lineheight-regular;
    color: $FUI-status-error;
    background-color: $white;
  }
}