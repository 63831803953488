@import '../../../styles/CBT/variables';

#CBT {
  .text-message {
    margin: 0 1rem 1rem;
    padding: 0.375rem 0;
    
    &.sender {
      background-color: $white; padding: 0.875rem;
    }

    > p {
      font-family: 'Segoe UI Regular';
      color: $FUI-darker;
      font-size: $fontsize-regular;
      line-height: $lineheight-regular;
    }

    ul, li, a {
      font-family: 'Segoe UI Regular';
      color: $FUI-darker;
      font-size: $fontsize-regular;
      line-height: $lineheight-regular;
    }

    a {
      font-family: 'Segoe UI SemiBold';
    }
  }
}