/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
      font-family: 'DM Sans Regular';
      font-style: normal;
      font-weight: normal;
      src: local('DM Sans Regular'), url('DM Sans Regular.woff') format('woff');
    }
    

    @font-face {
      font-family: 'DM Sans Italic';
      font-style: normal;
      font-weight: normal;
      src: local('DM Sans Italic'), url('DM Sans Italic.woff') format('woff');
    }
    

    @font-face {
      font-family: 'DM Sans Bold';
      font-style: normal;
      font-weight: normal;
      src: local('DM Sans Bold'), url('DM Sans Bold.woff') format('woff');
    }
    

    @font-face {
      font-family: 'DM Sans Bold Italic';
      font-style: normal;
      font-weight: normal;
      src: local('DM Sans Bold Italic'), url('DM Sans Bold Italic.woff') format('woff');
    }

    @font-face {
      font-family: 'DM Sans Medium';
      font-style: normal;
      font-weight: normal;
      src: local('DM Sans Medium'), url('DM Sans Medium.woff') format('woff');
    }

    @font-face {
      font-family: 'DM Sans Medium Italic';
      font-style: normal;
      font-weight: normal;
      src: local('DM Sans Medium Italic'), url('DM Sans Medium Italic.woff') format('woff');
    }