@import './landingpage/variables/index';

.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: $FUI-lighter-alt;
}

.page-error {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding: 1rem;
  font-family: 'Segoe UI Regular';
  font-size: $fontsize-regular;
  line-height: $lineheight-regular;
  color: $FUI-status-error;
  background-color: $white;
}