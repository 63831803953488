@import '../../../styles/QandA/variables';

#QandA {
  .tracker-component {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 15px;
    max-width: 440px;

    @media (min-width: $tablet) {
      padding-top: 20px;
      padding-bottom: 25px;
    }

    >p {
      margin-bottom: 0.5rem;
    }

    .input-label {
      color: $FUI-dark-alt;
      margin-top: 15px;
    }

    .number-input {
      margin-bottom: 0.5rem;

      @media (min-width: $tablet) {
        margin-bottom: 1rem;
      }

      label {
        font-family: 'Segoe UI SemiBold';
        font-weight: 400;
        font-size: $fontsize-small;
        line-height: $lineheight-small;
        margin-top: 0rem;
        margin-bottom: 0rem;
        color: $FUI-dark-alt;

        @media (min-width: $tablet) {
          margin-top: 0.375rem;
          margin-bottom: 1rem;
        }
      }

      input.ms-TextField-field {
        font-family: 'Segoe UI Regular';
        font-size: $fontsize-small;
        line-height: $lineheight-small;
        outline: none;
        border: none;

        &:read-only {
          background: transparent;
          cursor: not-allowed;
          border-bottom: 1px solid $FUI-grey30;
        }

        ::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: $FUI-grey110;
        }

        ::-moz-placeholder {
          /* Firefox 19+ */
          color: $FUI-grey110;
        }

        :-ms-input-placeholder {
          /* IE 10+ */
          color: $FUI-grey110;
        }

        :-moz-placeholder {
          /* Firefox 18- */
          color: $FUI-grey110;
        }
      }

      &.read-only {
        label {
          color: $FUI-disabled;
        }

        .ms-TextField-fieldGroup {

          &:active,
          &:focus,
          &:hover {
            border-bottom: 1px solid $FUI-grey30;
          }
        }
      }

      .ms-TextField-fieldGroup {
        border: none;
        outline: none;
        border-bottom: 1px solid $FUI-grey30;
        max-width: 250px;

        &::after,
        &:active,
        &:focus,
        &:hover,
        &:focus-visible {
          border: none;
          outline: none;
        }

        &:active,
        &:focus,
        &:hover {
          border-bottom: 1px solid $FUI-primary;
        }
      }
    }

    .dropdown-input {
      margin-bottom: 0.5rem;

      .ms-Dropdown {
        height: 100%;
        background-color: #fff;
        //border: solid 1px $FUI-grey50;
        border-bottom: 1px solid $FUI-input-border;

        .ms-Dropdown-title {
          padding-top: 0.75rem;
          min-height: 3.375rem;
          border: none;
        }

        .ms-Dropdown-caretDownWrapper {
          top: calc(50% - 6px);
          line-height: unset;

          .ms-Dropdown-caretDown {
            transition-duration: .1s;
            transition-property: -webkit-transform;
            transition-property: transform;
            transition-property: transform, -webkit-transform;
          }
        }
      }
    }

    &--footer {
      display: flex;
      justify-content: space-between;
      margin-top: 0.5rem;

      @media (min-width: $tablet) {
        margin-top: 1rem;
      }
    }

    .cta-button {
      background-color: $FUI-primary;
      border-radius: 2px;
      padding: 0.25rem 1.25rem;
      font-family: 'Segoe UI Regular';
      font-weight: 600;
      font-size: $fontsize-small;
      line-height: $lineheight-small;
      color: $white;

      @media (min-width: $tablet) {
        font-size: $fontsize-regular;
        line-height: $lineheight-regular;
      }

      &:hover {
        background-color: $FUI-dark-alt;
      }

      &:disabled {
        color: $FUI-disabled;
        cursor: not-allowed;
        background-color: $FUI-grey20;

        &:active {
          background-color: $FUI-grey20;
        }

        &:focus {
          outline: none;
          border: none;
        }
      }

      &:active {
        background-color: $FUI-dark;
      }

      &:focus {
        outline: 1px solid $FUI-primary;
        border: 1px solid $white;
      }
    }

    .primary-button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Segoe UI Regular';
      font-weight: 400;
      font-size: $fontsize-small;
      line-height: $lineheight-small;
      color: $FUI-red-dark;
      margin: 0;

      &:hover {
        border: 1px solid $black;
      }

      &:disabled {
        cursor: not-allowed;
        color: $FUI-disabled;

        &:focus,
        &:focus-visible,
        &:hover {
          outline: none;
          border: none;
        }
      }

      @media (min-width: $tablet) {
        font-size: $fontsize-regular;
        line-height: $lineheight-regular;
      }
    }

    .error-text {
      margin: 0;
      color: $FUI-status-error;
      font-size: $fontsize-small;

      @media (min-width: $tablet) {
        font-size: $fontsize-regular;
      }
    }
  }
}