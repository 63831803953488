$white: #fff;
$black: #000;

$blue-big-stone: #13263B;
$blue-indigo: #4475C5;
$blue-havelock: #6688D9;


// Fluent ui colors
$FUI-primary: #0078D4;
$FUI-dark: #005A9E;
$FUI-darker: #004578;
$FUI-lighter-alt: #EFF6FC;

$FUI-status-error: #A80000;
$FUI-error-fill: #FDE7E9;