@import '../../styles/landingpage/variables';

body {
  font-family: 'DM Sans Regular', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.landingpage {
  width: 100%;
  height: 100vh;
  overflow: hidden;

  @media (min-width: $tablet) {
    width: 100%;
  }

  .landingpage-wrapper {
    position: absolute;
    bottom: 4rem;
    display: flex;
    justify-content: center;
    width: 100vw;
    height: auto;
    z-index: 1;
  }

  .landingpage-main {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    background-color: $white;
    width: calc(100vw - 2rem);
    max-width: 235px;
    height: auto;
    padding: 1rem;
    box-shadow: 0px 4px 15px #68AFC0;

    h1 {
      font-size: $fontsize-regular;
      line-height: $lineheight-regular;
      margin-bottom: 0.5rem;

      @media (min-width: $tablet) {
        font-size: $fontsize-large;
        line-height: $lineheight-large;
      }
    }

    p {
      margin-bottom: 1rem;
      font-size: $fontsize-small;
      line-height: $lineheight-small;
    }

    button {
      display: flex;
      align-items: center;
      width: fit-content;
      padding: 1rem 2rem;
      cursor: pointer;
      border: none;
      background: linear-gradient(50.07deg, $blue-indigo 5.73%, $blue-havelock 86.07%);

      &:hover, &:active {
        background: $FUI-darker;
      } 
      
      .icon {
        margin: 0 -1rem -0.1rem 1.5rem;
        color: $white;
        font-size: $fontsize-large;
      }
    }
  }

  .landingpage-footer {
    display: flex;
    justify-content: space-around;
    position: absolute;
    bottom: 0;
    width: 100vw;
    padding: 1rem;
    background-color: $white;
    box-sizing: border-box;

    @media (min-width: $tablet) {
      justify-content: center;
    }


    a {
      text-decoration: none;
      margin-right: 3rem;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: $FUI-dark;
        text-decoration: underline;
      }
    }
  }
}