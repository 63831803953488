@import '../../fonts/SegoeUI/style.css';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import './page.scss';
@import './typography.scss';

@import '../../components/CBT/ConversationWrapper/style.scss';
@import '../../components/CBT/CallToActionButton/style.scss';
@import '../../components/CBT/ComponentNotFound/style.scss';
@import '../../components/CBT/ImageMessage/style.scss';
@import '../../components/CBT/PrimaryButton/style.scss';
@import '../../components/CBT/ProgressMessage/style.scss';
@import '../../components/CBT/RemovableInputBlock/style.scss';
@import '../../components/CBT/SectionOpening/style.scss';
@import '../../components/CBT/SectionOverview/style.scss';
@import '../../components/CBT/SenderInformation/style.scss';
@import '../../components/CBT/SliderInput/style.scss';
@import '../../components/CBT/SliderMessage/style.scss';
@import '../../components/CBT/TextInputObservation/style.scss';
@import '../../components/CBT/TextMessage/style.scss';
@import '../../components/CBT/TitleMessage/style.scss';