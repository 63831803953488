@import '../../../styles/CBT/variables';

#CBT {
  .section-opening {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    margin: 0 1rem;
  }
}