@import '../../../styles/CBT/variables';

#CBT {
  .section-overview {
    margin: 1.75rem 1rem 0;
  }

  .section-description {
    padding: 1.25rem 0;

    > p {
      margin-bottom: 1rem;
    }
  }
}