@import '../../../styles/CBT/variables';

#CBT {
  .slider-input {
    display: block;
    text-align: center;
    margin: 0 1rem 1rem;
    touch-action: none;
   
    > div {
      margin-top: 1rem;
    }

    .ms-Label {
      width: auto;
    }

    label {
      font-family: 'Segoe UI Regular';
      font-weight: 600;
      font-size: $fontsize-small;
      line-height: $lineheight-small;
      color: $FUI-darker;
    }

    .ms-Slider-container {
      cursor: pointer;
    }

    .ms-Slider-thumb {
      border-color: $FUI-darker; 
    }

    .ms-Slider-inactive {
      background-color: $FUI-grey60;
    }

    .ms-Slider-active {
      background-color: $FUI-darker;
    }

    &.read-only {
      label {
        color: $FUI-type-secondary;
      }

      .ms-Label {
        color: $FUI-grey190;
      }

      .ms-Slider-container {
        cursor: unset;
      }

      .ms-Slider-thumb {
        border-color: $FUI-input-border;
      }

      .ms-Slider-active {
        background: $FUI-input-border;
      }
   }
  }
}