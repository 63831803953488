@import '../../../styles/QandA/variables';

#QandA {

  // set overflow visible to show all message shadows
  .webchat__stacked-layout {

    &__content,
    &__message-row {
      overflow: visible;
    }
  }

  .web-chat {
    background-color: unset;
    height: auto;
    max-height: calc(calc(var(--vh, 1vh) * 100) - 150px);
    z-index: 2;

    @media (min-width: $tablet) {
      max-height: calc(calc(var(--vh, 1vh) * 100) - 191px);
    }

    .webchat__send-box__main {
      background-color: unset;
      border: none;
      justify-content: flex-end;
    }

    .webchat__basic-transcript {
      margin-bottom: 1rem;

      &__transcript {
        margin: 1rem 0;
      }

      &__activity {
        padding-top: 15px;
      }
    }

    .webchat__upload-button {
      display: none;
    }

    .app__avatarWithOnlineStatus__status {
      display: flex;
      align-items: center;

      .bot-icon {
        width: 30px;
        height: 30px;
        margin-right: 1rem;
      }

      .webchat__defaultAvatar {
        border-radius: unset;
        height: auto;
        width: auto;
        overflow: visible;
      }

      .webchat__initialsAvatar {
        position: relative;
        background-color: unset;
        height: auto;
        width: auto;
      }

      .webchat__initialsAvatar__initials {
        color: $blue-big-stone;
        font-family: 'DM Sans Medium';
        font-size: $fontsize-regular;
      }
    }

    .webchat__stacked-layout {
      &__status {
        height: 18px;
        margin-bottom: -18px;

      }

      &--hide-avatar {
        margin-top: -15px;
      }

      &--show-avatar {
        margin-top: 3rem;
      }

      &__main {
        flex-wrap: wrap;

        .bot-avatar,
        .user-avatar {
          padding-bottom: 15px;
        }
      }

      &__content {
        width: 100%;
        flex-basis: 100%;
      }

      &__status {
        .webchat__stacked-layout__avatar-gutter {
          display: none;
        }
      }

      .webchat__initialsAvatar--fromUser {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background-color: $blue-indigo;

        .webchat__initialsAvatar__initials {
          font-family: 'DM Sans Regular';
          font-size: $fontsize-small;
          color: $white;
        }
      }
    }

    form {
      width: 100%;
      justify-content: flex-end;

      input {
        width: auto;
        padding: 0.325rem 1rem;
        background-color: $white;
        border: 1px solid $FUI-input-border;
        border-radius: 0.5rem 0px 0.5rem 0.5rem;

        &:focus {
          border-color: $FUI-darker;
        }

        @media (min-width: $tablet) {
          max-width: 429px;
        }
      }
    }

    .webchat__icon-button {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      background-color: $blue-indigo;
      height: 32px;
      width: 32px;
      border-radius: 1rem;

      &:hover {
        cursor: pointer;
      }

      &::before {
        color: $white;
        content: '\27A4';
        margin-left: 0.2rem;
        margin-bottom: 0.1rem;
        font-size: 1rem;
        transform: rotate(-45deg);
      }

      &__shade {
        background-color: transparent;
      }

      >svg {
        display: none;
      }
    }

    .webchat__bubble--nub-on-top {
      border-radius: 0 1rem 1rem 1rem;
      background-color: transparent;
      overflow: visible;

      .webchat__bubble__content {
        border: none;
        min-height: unset;
        margin-left: -10px;
        border-radius: 0px;
        background-color: transparent;
        overflow: visible;

        >div {
          min-height: unset;
          padding-left: 25px;
          padding-right: 25px;
          background-color: #fff;
          box-shadow: $default-box-shadow;
          border-radius: 0 1rem 1rem 1rem;

          &.markdown {
            padding-top: 15px;
            padding-bottom: 15px;
          }

          &.webchat__adaptive-card-renderer {
            margin: -15px;
            padding: 0;
            padding-bottom: 10px;
            background-color: transparent;
            border-radius: 0px;
            box-shadow: none;
          }
        }

        .ac-adaptiveCard {
          padding-bottom: 0 !important;

          .ac-actionSet {
            flex-direction: initial !important;
            flex-wrap: wrap;
            margin-bottom: -10px;

            .ac-pushButton {
              font-family: Calibri, "Helvetica Neue", Arial, "sans-serif";
              font-size: inherit;
              font-weight: 400;
              width: auto;
              max-width: 100%;
              border-width: 2px;
              margin-right: 10px;
              margin-bottom: 10px;
              padding-left: 20px;
              padding-right: 20px;
            }
          }

          .ac-columnSet {
            padding: 15px;
            background-color: #fff;
            max-width: 490px;

            &.ac-selectable {
              position: relative;
              border: 1px solid #edebe9;
              box-shadow: 0 0.3px 0.9px rgb(0 0 0 / 10%), 0 1.6px 3.6px rgb(0 0 0 / 13%);

              &:hover {
                cursor: pointer;
                border-color: rgb(200, 198, 196);

                &::after {
                  content: " ";
                  position: absolute;
                  inset: 0px;
                  border: 1px solid rgb(200, 198, 196);
                  pointer-events: none;
                }
              }
            }

            .ac-textBlock {
              padding-top: 0.75rem;
              padding-right: 0.75rem;
            }

            .ac-horizontal-separator+.ac-textBlock {
              padding-top: 0;
              padding-right: 0.75rem;
              padding-bottom: 0.75rem;
            }
          }
        }
      }

      &.webchat__bubble--from-user {
        padding: 0.325rem 1rem;
        background-color: $blue-indigo;
        border-radius: 10px 0px 10px 10px;
        box-shadow: $default-box-shadow;

        .webchat__bubble__content {
          background-color: $blue-indigo;
          color: $white;
        }
      }

      &.webchat__stacked-layout__attachment {
        max-width: 100%;
        margin-bottom: -15px;

        .webchat__bubble__content {
          overflow: hidden;
          padding-bottom: 24px;
        }
      }
    }

    .webchat__suggested-actions__stack {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .poc-component {
    background-color: $blue-big-stone;
    color: $white;
  }
}