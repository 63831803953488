@import '../../styles/appscape/variables';

.page-dialog {
    background-color: $FUI-lighter-alt;
    max-width: 940px;
    margin: auto;
    padding: 2rem 1rem;
    color: $FUI-darker;

    @media (min-width: $tablet) {
        padding: 3rem 2rem;
    }

    h2 {
        font-family: 'DM Sans Medium';
        font-weight: 500;
        font-size: $fontsize-extra-extra-large;
        line-height: $lineheight-extra-extra-large;
        color: inherit;
        margin-top: 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 1rem;
    }

    >.p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
    }

    .p {
        font-family: 'DM Sans Medium';
        font-weight: 400;
        font-size: $fontsize-small;
        line-height: $lineheight-small;
        color: $FUI-type-secondary;
        margin-top: 0.5rem;

        @media (min-width: $tablet) {
            font-size: $fontsize-regular;
            line-height: $lineheight-regular;
        }
    }
}