@import '../../../styles/CBT/variables';

#CBT {
  .removable-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.25rem 0;
    padding: 10px;
    background-color: $white;
    box-shadow: $default-box-shadow;

    .ms-TextField {
      flex-grow: 1;

      input {
        color: $black;

        &::placeholder {
          color: $FUI-type-secondary;
        }
      }
    }

    button {
      margin: 0 0.5rem 0 1rem;
      flex-grow: 0;
      flex-shrink: 0;
      cursor: pointer;

      .icon {
        font-size: $fontsize-small;
        color: $FUI-input-border;
      }
    }
  }
}