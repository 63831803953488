@import '../../../styles/appscape/variables';

.appscape-footer {
  position: sticky;
  z-index: 3;
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 67px;
  bottom: 0;
  background-color: $white;
  box-shadow: 0px 4.8px 14.4px rgba(0, 0, 0, 0.18), 0px 25.6px 57.6px rgba(0, 0, 0, 0.22);

  @media (min-width: $tablet) {
    display: none;
  }

  .navigation-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;

    &.main-channel.active {
      span {
        color: $blue-indigo;
      }
    }

    &:active,
    &.active {
      span {
        color: $blue-indigo;
      }

      .icon {
        color: $blue-indigo;
      }
    }

    >img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-bottom: 0.25rem;
    }

    .icon {
      font-size: 18px;
      color: $FUI-type-secondary;
      margin-bottom: 0.25rem;

      &.vit {
        font-size: 24px;
        color: $blue-indigo;
      }
    }

    span {
      color: $FUI-type-secondary;
      font-family: 'DM Sans Medium';
      font-size: $fontsize-extra-small;
      font-weight: normal;
    }
  }
}