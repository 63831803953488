@import '../../../styles/CBT/variables';

#CBT {
  .conversation-wrapper {
    margin: 0 1rem 1rem;
    
    .text-message {
      background-color: $white;
      padding: 1rem;
      margin: 0.375rem 0 0 0;

      > p {
        margin: 0;
      }
    }

    .image-message {
      margin: 0.375rem 0;
    }
  }
}