@import '../../../styles/CBT/variables';

#CBT {
  .dropdown-input {
    margin: 0 1rem 0.5rem;

    .ms-Dropdown-container {
      width: 100%;
    }

    .ms-Dropdown-title {
      padding-top: 0.75rem;
      min-height: 3.375rem;
      border: none;

      &.read-only{
        background-color: $FUI-grey20;
      }
    }

    .ms-Dropdown {
      height: 100%;
      background-color: $white;
      border-bottom: solid 1px $FUI-input-border;

      .ms-Dropdown-caretDownWrapper .ms-Dropdown-caretDown {
        transition-duration: 0.1s;
        transition-property: transform;
      }

      &.is-open {
        .ms-Dropdown-caretDownWrapper .ms-Dropdown-caretDown {
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
        }
      }
    }

    .ms-Dropdown-caretDownWrapper {
      top: calc(50% - 6px);
      line-height: unset;
    }

    .ms-Dropdown-titleIsPlaceHolder {
      color: $FUI-grey110;
    }
  }

  .ms-Checkbox.is-checked:hover {
    .ms-Checkbox-checkbox {
      border-color: rgb(50, 49, 48);
      background-color: white;
      .ms-Checkbox-checkmark {
        color: rgb(96, 94, 92);
        opacity: 1;
      }
    }
  }
}