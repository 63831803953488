$white: #fff;
$black: #000;

// Fluent ui colors
$FUI-disabled: #A19F9D;
$FUI-input-border: #8A8886;
$FUI-type-secondary: #605E5C;
$FUI-grey20: #F3F2F1;
$FUI-grey30: #EDEBE9;
$FUI-grey40: #E1DFDD;
$FUI-grey50: #D2D0CE;
$FUI-grey60: #C8C6C4;
$FUI-grey90: #A19F9D;
$FUI-grey110: #8a8886;
$FUI-grey130: #0a0a09;
$FUI-grey190: #201F1E;
$FUI-primary: #0078D4;
$FUI-lighter-alt: #EFF6FC;
$FUI-lighter: #DEECF9;
$FUI-light: #C7E0F4;
$FUI-tertiary: #2B88D8;
$FUI-dark-alt: #106EBE;
$FUI-dark: #005A9E;
$FUI-darker: #004578;

$FUI-status-error: #A80000;
$FUI-error-fill: #FDE7E9;