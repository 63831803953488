@import '../../../styles/CBT/variables';

#CBT {
  .title-message {
    margin: 0 1rem 1rem;
    background-color: none;

    > .description {
      font-weight: 600;
    }  
  }
}