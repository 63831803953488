@import '../../styles/appscape/variables';

body {
  background-color: $FUI-lighter-alt;
  font-family: 'DM Sans Regular', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

.appscape {
  width: 100vw;
  height: 100vh;
  height: calc(var(--ch, 1vh) * 100);
}

.appscape-header {
  display: none;

  @media (min-width: $tablet) {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100%);
    height: 65px;
    background-color: $white;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
    color: $blue-big-stone;
  }

  .appscape-title {
    padding: 0 2rem;

    h1 {
      font-family: 'DM Sans Medium';
      font-size: $fontsize-large;
      font-weight: normal;
    }
  }
}

.appscape-main {
  display: flex;
  width: 100%;
  height: calc(100% - 65px);

  @media (min-width: $tablet) {
    height: calc(100% - 65px);
    width: 100%;
  }
}

.appscape-content {
  flex-grow: 1;
  height: 100%;
  overflow: auto;
  width: 100%;
  // padding-bottom: 5rem;

  @media (min-width: $tablet) {
    width: calc(100% - 24rem);
    padding-bottom: 0;
  }

  @media (max-width: $tablet-max) {
    &.navigation {
      display: none;
    }
  }
}