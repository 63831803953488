@import '../../../styles/CBT/variables';

#CBT {
  .error-display {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Segoe UI SemiBold';
    font-weight: 600;
    font-size: $fontsize-regular;
    line-height: $lineheight-regular;
    color: $white;
    padding: 1rem;
    margin: 0 1rem;

    background-color: $FUI-status-error;
  }
}