@import './variables';

#CBT {
  .h1 {
    font-family: 'Segoe UI SemiBold';
    font-weight: 600;
    font-size: $fontsize-regular;
    line-height: $lineheight-regular;
    color: $FUI-dark-alt;

    @media (min-width: $tablet) {
      font-size: $fontsize-large;
      line-height: $lineheight-large;
    }
  }

  .h2 {
    font-family: 'Segoe UI SemiBold';
    font-weight: 600;
    font-size: $fontsize-extra-large;
    line-height: $lineheight-extra-large;
    color: $FUI-primary;
    text-transform: uppercase;
    margin-top: 0.5rem;
  }

  .h3 {
    font-family: 'Segoe UI SemiBold';
    font-weight: 600;
    font-size: $fontsize-large;
    line-height: $lineheight-large;
    color: $FUI-darker;
  }

  .p {
    font-family: 'Segoe UI Regular';
    font-weight: 400;
    font-size: $fontsize-regular;
    line-height: $lineheight-regular;
    color: $black;
  }

  .span {
    font-family: 'Segoe UI SemiBold';
    font-weight: 600;
    font-size: $fontsize-regular;
    line-height: $lineheight-regular;
    color: $FUI-darker;
  }

  .label {
    font-family: 'Segoe UI SemiBold';
    font-weight: 600;
    font-size: $fontsize-small;
    line-height: $lineheight-small;
    margin-top: 0.375rem;
    margin-bottom: 1rem;
    color: $FUI-darker;
  }

  .description {
    font-family: 'Segoe UI Regular';
    color: $FUI-darker;
    font-size: $fontsize-regular;
    line-height: $lineheight-regular;
    margin-top: 0.375rem;
  }
}