@import '../../styles/InfoCards/variables';

#info {
  background-color: $grey-mercury;
  font-family: 'DM Sans Regular', Arial, Helvetica, sans-serif;
  height: 100%;

  .info-card-dialog {
    background-color: $grey-mercury;    
    max-width: 940px;
    margin: auto;
    padding: 2rem 1rem;

    @media (min-width: $tablet) {
      padding: 3rem 2rem;
    }

    > .h2 {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .h2 {
      font-family: 'DM Sans Medium';
      font-weight: 500;
      font-size: $fontsize-small;
      line-height: $lineheight-small;
      color: $black;
      margin-top: 0.5rem;

      @media (min-width: $tablet) {
        font-size: $fontsize-large;
        line-height: $lineheight-extra-large;
      }
    }

    > .p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }

    .p {
      font-family: 'DM Sans Medium';
      font-weight: 400;
      font-size: $fontsize-small;
      line-height: $lineheight-small;
      color: $FUI-type-secondary;
      margin-top: 0.5rem;

      @media (min-width: $tablet) {
        font-size: $fontsize-regular;
        line-height: $lineheight-regular;
      }
    }

    .info-cards {
      display: flex;
      flex-direction: column;
      margin-top: 0.825rem;

      @media (min-width: $tablet) {
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 1.5rem;
      }
    }
  }

  .page-error {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    padding: 1rem;
    font-family: 'Segoe UI Regular';
    font-size: $fontsize-regular;
    line-height: $lineheight-regular;
    color: $FUI-status-error;
    background-color: $white;
  }
}