@import '../../styles/CBT/variables';

#CBT {
  font-family: 'Segoe UI Regular', Arial, Helvetica, sans-serif;
  background-color: $FUI-lighter-alt;

  .temporary-text-div {
    font-size: 14px;
    position: absolute;
    left: -1000px;
    top: -1000px;
  }

  &.cbt-wrapper {
    width: auto;
    height: 100%;
  }

  .cbt-content {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    .cbt-header {
      display: flex;
      flex-direction: column;
      flex-grow: 0;
      flex-shrink: 0;
      padding: 0 1rem;

      .header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
      }
    }

    .cbt-footer {
      position: sticky;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-grow: 0;
      flex-shrink: 0;
      height: 3rem;
      margin: 0rem 0;
      padding: 1rem 1rem;
      z-index: 1;
      box-shadow: 0 4.8px 14.4px rgb(0 0 0 / 18%), 0 25.6px 57.6px rgb(0 0 0 / 22%);

      @media (min-width: $tablet) {
        background-color: #fff;
        border-top: solid 1px #D2D0CE;
        box-shadow: none;
      }

      .cbt-navigation {
        display: flex;
        margin: auto;

        .cbt-pageNumber {
          line-height: 1.375rem;
          margin: 0 15px;

          @media (max-width: $tablet) {
            line-height: 1.25rem;
            font-size: 0.875rem;
          }
        }
      }
    }

    .cbt-main {
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      overflow-x: hidden;
      width: 100%;
      height: 100%;

      .cbt-center-main {
        width: 100%;
        max-width: 800px;
        padding: 1rem 0 3rem 0;
        height: auto;
        margin-left: auto;
        margin-right: auto;
      }

      &.no-scroll {
        overflow-y: hidden;
      }

      /* fluent UI spinner */
      .circle-35 {
        width: 50px;
        height: 50px;
      }
    }
  }
}