@import '../../../styles/appscape/variables';

.CookieConsent {
    .text-container {
        position: relative;
        padding: 10px;
        padding-left: 45px;
        width: auto;
        word-break: break-word;

        .icon {
            font-size: 23px;
            font-weight: bold;
            color: $FUI-type-secondary;
            position: absolute;
            left: 15px;
            top: 10px;
        }

        .cookie-text {
            color: $black;
            font-family: 'Segoe UI';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
        }
    }

    .btn-container {
        display: block;
        margin-right: 65px;
        width: 190px;

        .btn {
            margin: 0px 4px;
            line-height: 100%;
            font-weight: 600;

            outline: transparent;
            font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
            -webkit-font-smoothing: antialiased;
            font-size: 14px;
            box-sizing: border-box;

            text-decoration: none;
            text-align: center;
            cursor: pointer;
            padding: 0px 16px;
            border-radius: 2px;
            min-width: 80px;

            user-select: none;

            &.btn-primary {
                width: 90px;
                height: 34px;
                left: 60vw;
                top: 23px;

                border: 1px solid $FUI-primary;
                background-color: $FUI-primary;
                color: $white;

                &:hover {
                    background-color: $FUI-dark-alt;
                    border: 1px solid $FUI-dark-alt;
                }
            }

            &.btn-secondary {
                width: 78px;
                height: 34px;
                left: 85vw;
                top: 23px;

                border: 1px solid $FUI-grey110;
                background-color: $white;
                color: 323130;

                &:hover {
                    background-color: $FUI-grey20;
                    color: $FUI-grey190
                }
            }
        }
    }
}