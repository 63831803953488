@import '../../../styles/CBT/variables';

#CBT {
  .primary-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Segoe UI Regular';
    font-weight: 400;
    font-size: $fontsize-small;
    line-height: $lineheight-small;
    color: $black;
    margin: 0 1rem;

    &:hover {
      color: $FUI-primary;
      cursor: pointer;

      & > .icon {
        color: $FUI-primary;
      }
    }

    &:disabled {
      color: $FUI-disabled;

      > .icon {
        color: $FUI-disabled;
      }
    }

    &:active {
      > .icon {
        color: $FUI-dark;
      }
    }

    @media (min-width: $tablet) {
      font-size: $fontsize-regular;
      line-height: $lineheight-regular;
    }

    .icon {
      display: block;
      margin: 0 0.5rem;
      font-size: $fontsize-regular;
      color: $FUI-dark;
    }
  }
}