@import './variables';

.landingpage {
  h1 {
    font-family: 'DM Sans Medium';
    font-weight: 600;
    font-size: $fontsize-large;
    line-height: $lineheight-large;
    color: $blue-big-stone;

    @media (min-width: $tablet) {
      font-size: $fontsize-huge;
      line-height: $lineheight-huge;
    }
  }

  p {
    font-family: 'DM Sans Medium';
    font-weight: 400;
    font-size: $fontsize-regular;
    line-height: $lineheight-regular;
    color: $black;

    @media (min-width: $tablet) {
      font-size: $fontsize-large;
      line-height: $lineheight-large;
    }
  }

  span {
    font-family: 'DM Sans Medium';
    font-weight: 400;
    font-size: $fontsize-regular;
    line-height: $lineheight-regular;
    color: $white;
  }

  a {
    font-family: 'DM Sans Medium';
    font-weight: 500;
    font-size: $fontsize-small;
    line-height: $lineheight-small;
    color: $FUI-primary;
  }
}