@import '../styles/appscape/variables';

.tracker-list {
    margin: 25px 0;

    li {
        list-style-type: circle;
        margin-left: 25px;

        span:hover {
            color: $blue-indigo;
            cursor: pointer;
        }

    }
}

.tracker-details {
    .tracker-name {}

    .tracker-last-reading {
        margin-bottom: 1rem;

        .tracker-last-reading-values {
            margin-right: 10px;
        }

        .tracker-last-reading-date {
            font-size: $fontsize-extra-small;
            color: $FUI-type-secondary;
        }
    }

    .tracker-chart {
        margin-bottom: 1rem;
    }

    .tracker-readings-table {}
}